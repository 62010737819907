// 參數 /////////////
$font-o: 'Open Sans', sans-serif;
$font-n: 'Noto Sans', sans-serif;
$lh-11: 1.1;
$lh-14: 1.4;
$lh-15: 1.5;
$lh-17: 1.7;
$lh-19: 1.9;
$fw-5: 400;
$fw-6: 700;
$fs-75: 0.75rem;
$fs-85: 0.85rem;
$fs-9: 0.9rem;
$fs-95: 0.95rem;
$fs-1: 1rem;
///////////////////////
// colors /////////////
$main-color: #104935;
$btn-hover: #3C916B;
$body-color: #222b45;
$title: #222b45;
$underline: #C5CEE0;
$title-gr: #42826B;
$code-blue: #006FB1;
$hint: #8F9BB3;
$white: #fff;
$red: #CC2A32;
$blue: #0090CE;
///////////////////////
$alpha-3: 0.3;
$alpha-5: 0.5;
$alpha-8: 0.8;
///////////////////////
// screen /////////////
$screen-xs: 576px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;
$screen-xs-max: ($screen-xs - 1) !default;
$screen-sm-max: ($screen-sm - 1) !default;
$screen-md-max: ($screen-md - 1) !default;
$screen-lg-max: ($screen-md - 1) !default;
@import 'settings';
@import 'mixin';

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  line-height: 1.5;
}

body {
  margin: 0;
  font-family: $font-n;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: normal;
  color: $body-color;
  background-color: #f2f2f2;
}

a {
  color: $blue;
  @include transition(0.5s);

  &:hover,
  &:active,
  &:focus {
    color: #38b9e1;
    outline: none;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: $font-o;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-xs) {
    max-width: 540px;
  }

  @media (min-width: $screen-sm) {
    max-width: 720px;
  }

  @media (min-width: $screen-md) {
    max-width: 960px;
  }

  @media (min-width: $screen-lg) {
    max-width: 1140px;
  }
}

.font_o {
  font-family: $font-o !important;
}

.title {
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  color: $title-gr !important;
  font-weight: 600 !important;
  font-family: $font-o !important;
  font-size: 18px !important;

  &.label_title {
    color: $title !important;
    font-size: 16px !important;
  }

  &.title_blue {
    color: #006fb1 !important;
  }
}

.divider {
  display: block;
  margin: 7px 0;
  height: 1px;
  background-color: #c5cee0;
}

.border_form {
  padding: 20px;
  border: 1px solid rgba(16, 73, 53, 0.48);
  box-sizing: border-box;
  border-radius: 6px;
  @include border-radius(6px);

  &.boder_form_done {
    color: $title !important;

    .label_title {
      color: $title !important;
    }
  }
}

.btn {
  min-width: 100px;
  padding: 8px 20px;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-family: $font-o !important;

  &.btn-primary {
    color: $white;
    background-color: $main-color;
    font-weight: 500;

    &:hover {
      background-color: $btn-hover;
    }

    &:active {
      background-color: #0b3e31;
    }
  }

  &.btn-danger {
    color: $white;
    background-color: $red;
    margin-left: 20px;
    font-weight: 500;

    &:hover {
      background-color: lighten($red, 5%);
    }

    &:active {
      background-color: darken($red, 10%);
    }
  }
}

.buttons {
  text-align: center;
}

.MuiInput-underline {
  &:before {
    border-bottom: 1px solid $underline !important;
  }

  &:after {
    border-bottom: 2px solid $main-color !important;
  }

  &.Mui-error:after {
    border-color: #f44336 !important;
  }
}

#result {
  &.accept {
    color: '#049956';
  }

  &.reject {
    color: '#CC2A32';
  }
}

.Mui-disabled {
  color: rgba(0, 0, 0, 0.36) !important;
}

.MuiTab-root {
  height: 6px;
  border-bottom: 1px solid rgba(16, 73, 53, 0.32);
  @include border-radius(20px);
}